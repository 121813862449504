import React, { useEffect, useState } from "react";
import { Box, Button, TextField, MenuItem, Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import MDTypography from "components/MDTypography";
import Lottie from "lottie-react";
import SelectFile from "assets/lottie/Animation - 1709585629758.json";
import CheckingFile from "assets/lottie/Animation - 1709585724092.json";
import MDBox from "components/MDBox";
import { snackbar } from "components/awesome_snackbar/snackbar";
import MDButton from "components/MDButton";
import { createTask } from "utils/requests/task/createTask";
import { getTeachersClassesByTeacherId } from "utils/requests/teachers/assignClass";
import { profile } from "context/profile";

// const allowedFileTypes = [
//   "image/jpeg",
//   "image/png",
//   "application/pdf",
//   "application/msword",
//   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//   "application/vnd.ms-excel",
//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
// ];

const TaskForm = () => {
  const { role } = profile;
  const [formData, setFormData] = useState({
    class_id: null,
    // attachment: null,
    description: "",
    dueDate: null,
    type: "homework",
    semester: "1",
  });
  const [assignClass, setAssignClass] = useState([]);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAssignedClassData = async () => {
      if (role === "teacher") {
        const res = await getTeachersClassesByTeacherId();
        if (res.success) {
          const assignedClass = res.data.map((item) => item.class);
          setAssignClass(assignedClass ? assignedClass : []);
        }
      }
    };
    fetchAssignedClassData();
  }, []);
  const validateForm = () => {
    const newErrors = {};

    if (!formData.class_id) {
      newErrors.class_id = "Class is required.";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required.";
    }

    if (!formData.dueDate) {
      newErrors.dueDate = "Due date is required.";
    }

    if (!formData.type) {
      newErrors.type = "Task type is required.";
    }

    if (!formData.semester) {
      newErrors.semester = "Semester is required.";
    }
    // if (!formData.attachment) {
    //   newErrors.attachment = "Attachment is required.";
    // }

    setErrors(newErrors);

    // Return true if no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     const fileExtension = file.name.split(".").pop().toLowerCase();
  //     const allowedExtensions = [".jpg", ".jpeg", ".png", ".pdf", ".docx", ".xls", ".xlsx"];
  //     const isValidType = allowedFileTypes.includes(file.type);
  //     const isValidExtension = allowedExtensions.includes(`.${fileExtension}`);

  //     if (!isValidType || !isValidExtension) {
  //       setErrors({
  //         ...errors,
  //         attachment:
  //           "Invalid file type. Please upload a valid image, PDF, DOCX, or Excel document.",
  //       });
  //       return;
  //     }

  //     // Clear errors and update formData with the file
  //     setErrors({ ...errors, attachment: "" });
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       attachment: file, // This correctly stores the file object
  //     }));
  //   }
  // };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dueDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop if validation fails
    }

    setLoading(true);
    try {
      console.log("FormData Entries:", JSON.stringify(formData, null, 2));

      // Create FormData instance
      const formDataToSend = new FormData();
      formDataToSend.append("class_id", formData.class_id);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("dueDate", formData.dueDate);
      formDataToSend.append("type", formData.type);
      formDataToSend.append("semester", formData.semester);

      // // If a file is selected, append it to FormData
      // if (formData.attachment) {
      //   formDataToSend.append("attachment", formData.attachment);
      // }

      const res = await createTask(formDataToSend); // Ensure your API accepts FormData

      if (res.success) {
        snackbar("success", res.message);
        setFormData({
          // attachment: null,
          class_id: null,
          description: "",
          dueDate: null,
          type: "homework",
          semester: "1",
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
      snackbar("error", "Something went wrong while creating the task.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" mb={3}>
        Task Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              select
              label="Class"
              value={formData.class_id} // Bind to class_id for form submission
              onChange={handleChange}
              name="class_id" // Ensure this matches the state key
              sx={{
                "& .MuiInputBase-root": {
                  height: "44px",
                },
              }}
            >
              {assignClass
                .filter(
                  (cls, index, self) => index === self.findIndex((c) => c.class_id === cls.class_id)
                )
                .map((cls) => (
                  <MenuItem key={cls.class_id} value={cls.class_id}>
                    {cls.grade_level} {cls.section} {/* Display grade and section */}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {/* Task Type */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              select
              label="Task Type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              sx={{ "& .MuiInputBase-root": { height: "44px" } }}
            >
              <MenuItem value="homework">Homework</MenuItem>
              <MenuItem value="assignment">Assignment</MenuItem>
            </TextField>
          </Grid>

          {/* Semester */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              select
              label="Semester"
              name="semester"
              value={formData.semester}
              onChange={handleChange}
              sx={{ "& .MuiInputBase-root": { height: "44px" } }}
            >
              {["1", "2", "3", "4", "5", "6"].map((sem) => (
                <MenuItem key={sem} value={sem}>
                  {sem}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              fullWidth
              multiline
              rows={4}
              required
              value={formData.description}
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>

          {/* Due Date */}
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Due Date"
              value={formData.dueDate}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  required
                  sx={{
                    "& .MuiInputBase-root": { height: "44px" },
                    "& .MuiInputBase-input": { width: "500px" }, // Adjust the width here
                  }}
                  {...params}
                  error={!!errors.dueDate}
                  helperText={errors.dueDate}
                />
              )}
            />
          </Grid>

          {/* Attachment */}
          {/* <Grid item xs={12}>
            <MDBox textAlign="center">
              <input
                type="file"
                accept={allowedFileTypes.join(",")}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="task-file-upload"
              />
              <label htmlFor="task-file-upload" className="cursor-pointer">
                <Lottie
                  animationData={formData.attachment ? CheckingFile : SelectFile}
                  loop={false}
                  style={{ height: 100 }}
                />
                <MDTypography variant="caption">
                  {formData.attachment ? formData.attachment.name : "Click or Drag to Upload File"}
                </MDTypography>
              </label>
              {errors.attachment && (
                <MDTypography color="error" variant="caption">
                  {errors.attachment}
                </MDTypography>
              )}
            </MDBox>
          </Grid> */}

          {/* Submit Button */}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <MDButton
              variant="contained"
              color="info"
              style={{ color: "white" }}
              type="submit"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Task"}
            </MDButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default TaskForm;
