import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables/teachersTable";
import Tables2 from "layouts/tables/registerTable";
import Broadcast from "layouts/billing";
import Classes from "layouts/Classes";
import Examtypes from "layouts/Examtype/index";
import Subjects from "layouts/Subjects";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import { Podcasts, Schedule, Task } from "@mui/icons-material";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import QuizIcon from "@mui/icons-material/Quiz";
import Forgotten from "layouts/authentication/forgottenpassword";
import Attendance from "layouts/attendance/attendance";
import AssignClass from "layouts/assignClass";
import ExamResult from "layouts/examResult";
import Roaster from "layouts/roaster";
import SchoolPlan from "layouts/schoolPlan";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ClassSchedule from "layouts/classSchedule.js";
import RequestPage from "layouts/request";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import assign from "./assets/images/assign.png";
import EventsPage from "layouts/events";
import ExamNotification from "layouts/examNotification";
import GenerateExam from "layouts/ExamSheet";
import TeacherDashboard from "./layouts/teacherDashboard";
import Tasks from "./layouts/task";

let routes = [];
routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <TeacherDashboard />,
  },
  {
    type: "collapse",
    name: "School Plans",
    key: "schoolPlans",
    icon: <Icon fontSize="small">alarm</Icon>,
    route: "/schoolPlans",
    component: <SchoolPlan />,
  },
  {
    type: "collapse",
    name: "Staffs",
    key: "staffs",
    icon: <CastForEducationIcon />,
    route: "/staffs",
    component: <Tables routename="Teachers" />,
  },

  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Task />,
    route: "/tasks",
    component: <Tasks />,
  },
  {
    type: "collapse",
    name: "Students",
    key: "students",
    icon: <SchoolIcon />,
    route: "/students",
    component: <Tables routename="Students" />,
  },
  {
    type: "collapse",
    name: "Classes",
    key: "classes",
    icon: <ClassIcon />,
    route: "/classes",
    component: <Classes />,
  },
  {
    type: "collapse",
    name: "Exam",
    key: "examtypes",
    icon: <QuizIcon />,
    route: "/examtypes",
    component: <Examtypes />,
  },
  {
    type: "collapse",
    name: "Subjects",
    key: "subjects",
    icon: <AutoStoriesIcon />,
    route: "/subjects",
    component: <Subjects />,
  },
  {
    type: "collapse",
    name: "Schedule",
    key: "schedules",
    icon: <Schedule />,
    route: "/schedules",
    component: <ClassSchedule />,
  },
  {
    type: "collapse",
    name: "Broadcast",
    key: "broadcast",
    icon: <Podcasts />,
    route: "/broadcast",
    component: <Broadcast />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/events",
    component: <EventsPage />,
  },
  {
    type: "collapse",
    name: "Question",
    key: "question",
    icon: <QuizIcon />,
    route: "/question",
    component: <GenerateExam />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Exam Notifications",
    key: "examNotifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/examNotifications",
    component: <ExamNotification />,
  },
  {
    type: "collapse",
    name: "Requests",
    key: "requests",
    icon: <QuestionMarkIcon />,
    route: "/requests",
    component: <RequestPage />,
  },
  {
    type: "collapse",
    name: "Assign Class",
    key: "assignClass",
    icon: (
      <Icon fontSize="small">
        <img src={assign} alt="assign class" />
      </Icon>
    ),
    route: "/assignClass",
    component: <AssignClass />,
  },
  {
    type: "collapse",
    name: "Result",
    key: "result",
    icon: <AssignmentTurnedInIcon />,
    route: "/result",
    component: <ExamResult />,
  },
  {
    type: "collapse",
    name: "Roaster",
    key: "roaster",
    icon: <AssignmentTurnedInIcon />,
    route: "/roaster",
    component: <Roaster />,
  },
  {
    type: "collapse",
    name: "Attendance",
    key: "attendance",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/attendance",
    component: <Attendance />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Register",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/registerTable",
    component: <Tables2 />,
  },
  {
    type: "collapse",
    name: "Reset-password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/reset",
    component: <Forgotten />,
  },
];

export default routes;
