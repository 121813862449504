const adminRoutes = [
  "dashboard",
  "staffs",
  "broadcast",
  "notifications",
  "logout",
  "students",
  "schedules",
  "assignClass",
  "events",
  "schoolPlans",
  "profile",
];
const registrarRoutes = [
  "staffs",
  "broadcast",
  "students",
  "classes",
  "subjects",
  "examtypes",
  "profile",
  "schedules",
  "logout",
  "assignClass",
  "schoolPlans",
  "examNotifications",
];
const teacherRoutes = [
  "home",
  "broadcast",
  "attendance",
  "result",
  "tasks",
  "roaster",
  "requests",
  "examNotifications",
  "question",
  "notifications",
  "profile",
  "logout",
];

export const Role_Route = (role) => {
  if (role !== null || role !== "") {
    if (role.toLowerCase() === "admin") return adminRoutes;
    else if (role.toLowerCase() === "registrar") return registrarRoutes;
    else if (role.toLowerCase() === "teacher") return teacherRoutes;
    else if (role.toLowerCase() === "encoder") return registrarRoutes;
    else return [];
  }
};

export const Role_Route_init = (role) => {
  if (role !== null || role !== "") {
    if (role.toLowerCase() === "admin") return "dashboard";
    else if (role.toLowerCase() === "registrar") return "staffs";
    else if (role.toLowerCase() === "teacher") return "home";
    else return [];
  }
};
