import axiosInstance from "config/axios-config.js";

export const createTask = async (data) => {
  let response = {
    success: false,
    message: "",
    data: null,
  };

  try {
    const res = await axiosInstance.post("/task", data);
    console.log("response from server is", JSON.stringify(res, null, 2));
    if (res.data.success) {
      response = {
        success: true,
        message: res.data.message,
        data: res.data.data,
      };
    } else {
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error.response?.data?.message || "An unexpected error occurred.";
  }

  return response;
};

export const updatetasks = async ({ task_id, data }) => {
  const response = {
    data: null,
    message: "",
    success: false,
  };
  try {
    const res = await axiosInstance.put(`/task/${task_id}`, data);
    if (res.data.success) {
      response.success = res.data.success;
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error?.response?.data?.message;
  }
  return response;
};

export const deletetask = async (task_id) => {
  const response = {
    data: null,
    message: "",
    success: false,
  };
  try {
    const res = await axiosInstance.delete(`/task/${task_id}`);
    if (res.data.success) {
      response.success = res.data.success;
      response.message = res.data.message;
    } else {
      response.message = res.data.message;
    }
  } catch (error) {
    response.message = error?.response?.data?.message;
  }
  return response;
};
