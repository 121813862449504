import React, { useEffect, useState } from "react";
import { Grid, Avatar, IconButton } from "@mui/material";
import { ArrowBack, Add as AddIcon } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditableTable from "helper/editableTable";
import { LoadinSpiner } from "components/LoadingSpinner";
import TaskForm from "./taskForm";
import { useMemo } from "react";
import { gettasksForTeacher } from "utils/requests/task/getTask";
import { updatetasks } from "utils/requests/task/createTask";
import { deletetask } from "utils/requests/task/createTask";
import ConfirmationDialog from "helper/comfirmationOfDelete";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { snackbar } from "components/awesome_snackbar/snackbar";

const validateRequired = (value) => {
  return (typeof value === "string" && value.trim().length > 0) || typeof value === "number";
};

// Function to validate user inputs
const validateTask = (data) => {
  return {
    type: !validateRequired(data.type) ? "Task type is required" : "",
    class: !validateRequired(data.class) ? "class is required" : "",
    description: !validateRequired(data.description) ? "Description is required" : "",
    dueDate: !validateRequired(data.dueDate) ? "DueDate is required" : "",
    status: !validateRequired(data.status) ? "Status is required" : "",
  };
};
const Tasks = () => {
  const [addVisibility, setAddVisibility] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rows, setRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const columnsDefinition = useMemo(
    () => [
      {
        accessorKey: "type",
        header: "Task Type",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, type: undefined })),
        },
      },
      {
        accessorKey: "class_id",
        header: "Class",
        muiEditTextFieldProps: {
          disabled: true,
          required: true,
          error: !!validationErrors?.class,
          helperText: validationErrors?.class,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, class: undefined })),
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, description: undefined })),
        },
      },
      {
        accessorKey: "dueDate",
        header: "DueDate",
        muiEditTextFieldProps: {
          type: "date",
          required: true,
          error: !!validationErrors?.dueDate,
          helperText: validationErrors?.dueDate,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, dueDate: undefined })),
        },
        size: 550,
      },
      {
        accessorKey: "status",
        header: "Status",
        muiEditTextFieldProps: {
          disabled: true,
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () => setValidationErrors((prev) => ({ ...prev, status: undefined })),
        },
        size: 550,
      },
    ],
    [validationErrors]
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await gettasksForTeacher();
        console.log("response from server: ", JSON.stringify(response, null, 2));
        setRows(response.data || []);
        setLoading(false);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, [refresh]);

  const onEdit = async (updatedRow) => {
    const { values, row, table } = updatedRow;
    const newValidationErrors = validateTask(values);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});
    const task_id = row.original?.task_id;

    if (task_id) {
      try {
        const response = await updatetasks(task_id, values);

        if (response.success) {
          snackbar("success", response.message);
          setRefresh(!refresh);
          table.setEditingRow(null);
        } else {
          snackbar("error", response.message);
        }
      } catch {
        snackbar("error", "Failed to update subject");
      }
    }
  };

  const handleDelete = async () => {
    const task_id = deleteRow.original?.task_id;
    if (task_id) {
      try {
        const response = await deletetask(task_id);
        if (response.success) {
          snackbar("success", response.message);
          setRefresh(!refresh);
        } else {
          snackbar("error", response.message);
        }
      } catch (error) {
        snackbar("error", "Failed to delete task ");
      }
    }
    setDialogOpen(false);
  };

  const onDelete = (row) => {
    setDeleteRow(row);
    setDialogOpen(true);
  };

  const handleOnClick = () => {
    setAddVisibility(!addVisibility);
  };

  const arrowBackHandler = () => {
    setAddVisibility(!addVisibility);
    setRefresh(!refresh);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={10}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white" style={{ flexGrow: 1 }}>
                Tasks
              </MDTypography>

              <MDBox display="flex" alignItems="center">
                <Avatar
                  style={{
                    backgroundColor: "white",
                    padding: 8,
                    cursor: "pointer",
                  }}
                >
                  <IconButton style={{ padding: 0 }}>
                    {addVisibility ? (
                      <ArrowBack style={{ color: "#1976d2" }} onClick={arrowBackHandler} />
                    ) : (
                      <AddIcon style={{ color: "#1976d2" }} onClick={handleOnClick} />
                    )}
                  </IconButton>
                </Avatar>
              </MDBox>
            </MDBox>
            {addVisibility ? (
              <TaskForm onSubjectAdded={() => setRefresh(!refresh)} />
            ) : (
              <MDBox mt={2} ml={2} mr={2}>
                {loading ? (
                  <LoadinSpiner />
                ) : rows.length > 0 ? (
                  <EditableTable
                    columns={columnsDefinition}
                    data={rows}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                  />
                ) : (
                  <MDTypography
                    variant="body2"
                    style={{ textAlign: "center" }}
                    color="textSecondary"
                  >
                    No data available.
                  </MDTypography>
                )}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmationDialog
        open={dialogOpen}
        title="Delete subject"
        message="Are you sure you want to delete this subject? This action will affect associated data."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </DashboardLayout>
  );
};

export default Tasks;
