import { axiosInstanceAuth } from "config/axios-config";

export const getTasks = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const localResponse = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const response = await axiosAuth.get(`/task`);
    if (response.data.success) {
      localResponse.message = response.data.message;
      localResponse.success = response.data.success;
      localResponse.data = response.data.data;
    } else {
      localResponse.message = response.data.message;
    }
  } catch (error) {
    localResponse.message = error?.response?.data?.message;
  }
  return localResponse;
};
export const gettasksForTeacher = async () => {
  const axiosAuth = axiosInstanceAuth().axiosInstance;
  const localResponse = {
    success: false,
    message: "",
    data: null,
  };
  try {
    const response = await axiosAuth.get(`/task/teacher`);
    if (response.data.success) {
      localResponse.message = response.data.message;
      localResponse.success = response.data.success;
      localResponse.data = response.data.data;
    } else {
      localResponse.message = response.data.message;
    }
  } catch (error) {
    localResponse.message = error?.response?.data?.message;
  }
  return localResponse;
};
