import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchDashboard } from "utils/requests/others/dashboard";
import { getNotification } from "../../utils/requests/notification/notification";
import { notificationGetter } from "context/notification";
import { LoadinSpiner } from "components/LoadingSpinner";
import CardItem from "./card";
import { teacherDashboard } from "utils/requests/teachers/teacher";
import GroupsIcon from "@mui/icons-material/Groups";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ClassIcon from "@mui/icons-material/Class";
import GenderDistributionChart from "./genderDistributionChart";
import EventCalendar from "./Calender";
import Announcements from "./Announcements";
import BigCalendarContainer from "./BigCalendarContainer";
import StudentPerformanceChart from "./StudentPerformanceChart";

const TeacherDashboard = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataJson, setDataJson] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchStaffData = async () => {
      const res = await teacherDashboard();
      console.log(res);
      if (res.success) {
        setData(res.data);
      } else {
        setData({});
      }
    };
    fetchStaffData();
  }, []);

  const setCardBoard = (data) => {
    setDataJson(data?.data || {}); // Ensure dataJson is an object
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await fetchDashboard(setCardBoard);
      await getNotification();
      setIsLoading(false);
      setNotifications(notificationGetter());
    };
    fetchData();
  }, []);

  if (isLoading) return <LoadinSpiner />;
  return (
    <DashboardLayout>
      <DashboardNavbar
        notificationCounter={notifications.length}
        notificationLists={notifications}
      />
      <div className="flex flex-col lg:flex-row mt-3">
        <div className="w-full xl:w-[75%]">
          <div className="flex flex-wrap justify-center items-center gap-10 mb-20">
            <div className="w-full md:w-[calc(50%-12px)] xl:w-[calc(33.333%-60px)]">
              <CardItem
                label="Total Students"
                value={data?.totalStudentCount || 0}
                Icon={GroupsIcon}
              />
            </div>
            <div className="w-full md:w-[calc(50%-12px)] xl:w-[calc(33.333%-60px)]">
              <CardItem label="Classes" value={data?.totalClasses || 0} Icon={ClassIcon} />
            </div>
            <div className="w-full md:w-[calc(50%-12px)] xl:w-[calc(33.333%-60px)]">
              <CardItem label="Subjects" value={data?.totalSubjects || 0} Icon={AutoStoriesIcon} />
            </div>
          </div>
          <MDBox mt={4.5}>
            <MDBox mb={3}>
              <div className="flex flex-col xl:flex-row justify-center gap-10">
                <div>
                  <GenderDistributionChart
                    femaleCount={data?.totalFemaleCount || 0}
                    maleCount={data?.totalMaleCount || 0}
                    otherCount={data?.totalOtherCount || 0}
                  />
                </div>
                <div className="flex-1">
                  <StudentPerformanceChart performance={data?.studentPerformance || []} />
                </div>
              </div>
            </MDBox>
          </MDBox>
          <div className="bg-white p-4 rounded-md">
            <h1 className="text-xl font-semibold">Schedule</h1>
            <BigCalendarContainer type="teacherId" />
          </div>
        </div>
        <div className="w-full xl:w-[25%] flex flex-col gap-8">
          <EventCalendar />
          <Announcements />
        </div>
      </div>
      <Footer />
    </DashboardLayout>
  );
};

export default TeacherDashboard;
