import React, { useState, useEffect } from "react";
import { getEventsForTeacher } from "utils/requests/event/getEvents";

const Announcements = () => {
  const [loading, setLoading] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const res = await getEventsForTeacher();
      if (res.success) {
        setLoading(false);
        setEvents(res.data);
      } else {
        setLoading(false);
        setEvents([]);
      }
    };
    fetchEvents();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  const displayedData = viewAll ? events : events.slice(0, 5);

  return (
    <div className="bg-white p-4 rounded-md">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold">Announcements</h1>
        <span
          className="text-xs text-blue-500 cursor-pointer"
          onClick={() => setViewAll((prev) => !prev)} // Toggle viewAll state
        >
          {viewAll ? "Show Less" : "View All"}
        </span>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        {displayedData.map((data) => (
          <div key={data.event_id} className={`rounded-md p-4`}>
            <div className="flex items-center justify-between">
              <h2 className="font-medium text-[16px]">{data.title}</h2>
              <span className="text-xs text-gray-400 bg-white rounded-md px-1 py-1">
                {new Intl.DateTimeFormat("en-GB").format(new Date(data.date))}
              </span>
            </div>
            <p className="text-sm text-gray-400 mt-1">{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Announcements;
